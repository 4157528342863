import { IconName } from "@blueprintjs/icons";

export interface Section {
  readonly title: string;
  readonly entries: ReadonlyArray<Entry>;
}

export interface Entry {
  readonly title?: string;
  readonly subtitle?: string;
  readonly body: string;
  readonly drawer?: DrawerProperties;
  readonly hideUnlessVariants?: string[];
}

export interface DrawerProperties {
  readonly clickableIcon: IconName;
  readonly drawerIcon: IconName;
  readonly title: string;
  readonly body: JSX.Element;
}

export const EDUCATION: Section = {
  title: "Education",
  entries: [
    { title: "Duke-NUS Medical School, Singapore",
      subtitle: "<b>2023 – Present</b>",
      body: "PhD Student in Quantitative Biology and Medicine" },
    { title: "National University of Singapore",
      subtitle: "<b>2017 – 2021</b>",
      body: "B.Sc. (Hons.), Major in Computational Biology;<br>" +
        "University Scholars Programme (USP). CAP: 4.77/5.00" }
  ]
}

export const EXPERIENCE: Section = {
  title: "Experience",
  entries: [
    { title: "Bioinformatics Specialist, National Cancer Centre Singapore",
      subtitle: "<b>2021 &ndash; 2023</b> with A/P Ann SG Lee",
      body: `<ul>
        <li>Communicated, wrote, and published six first-author papers
        (<a href="#crc">1</a>,<a href="#met">2</a>,<a href="#stormet">3</a>,<a href="#lcc">4</a>,<a href="#bc">5</a>,<a href="#npc">6</a>)</li>
        <li>Designed tidy, reproducible, and version-controlled pipelines and analyses</li>
        <li>Processed and analyzed the genomes and methylomes for >1,000 patients</li>
        <li>Worked and learnt bioinformatics independently as the sole computational scientist on staff</li>
      </ul>` },
    { title: "Student Researcher, NUS University Scholars Programme",
      subtitle: "<b>2019 &ndash; 2021</b> with A/P Johan Geertsema and Prof Tony Harland (Uni. Otago)",
      body: `<ul>
        <li>Planned and analyzed semi-structured interviews for a qualitative article (<a href="#the">7</a>)</li>
        <li>Led peers to work consistently on the manuscript in a pandemic, independent of supervision</li>
      </ul>` },
    { title: "Student Researcher, NUS Department of Biological Sciences",
      subtitle: "<b>2018 &ndash; 2021</b> with Prof Greg-Tucker Kellogg",
      body: `<ul>
        <li>Conceptualized, created, and published a mobile application for AR protein visualization
        (<a href="#ar">8</a>)</li>
        <li>Explored novel methods for microarray, RNA-seq, and scRNA-seq analysis</a>
      </ul>` },
    { title: "Assorted projects at NUS", body: "" },
    { subtitle: "<b>2020 (Winter)</b> with Asst. Prof. Cynthia Siew",
      body: `<ul>
        <li>Add features, tests, and fix bugs in <i><a href="https://github.com/csqsiew/spreadr">spreadr</a></i>, an R package for networks in cognitive science</li>
      </ul>` },
    { subtitle: "<b>2019 (Summer)</b> with Prof Laederach (University of North Carolina at Chapel Hill)",
      body: `<ul>
        <li>Developed pipelines <a href="https://bedshape.ningyuan.io/"><i>bedshape</i></a> and <a href="https://trcls.ningyuan.io/"><i>trcls</i></a> for RNA structure inference</li>
      </ul>` },
    { subtitle: "<b>2018 &ndash; 2020</b> with various lecturers",
      body: `<ul>
        <li>Served as teaching assistant for CS1010S, CS1010E (Python 3); and CS1101S (JavaScript)</li>
        <li>Served as teaching assistant for the quantitative reasoning centre (JavaScript)</li>
      </ul>` },
    { subtitle: "<b>2018 (Summer)</b> <span>with A/P Martin Henz</span>",
      body: `<ul>
        <li>Developed <a href="https://github.com/source-academy/frontend">Source Academy</a>, an online IDE for teaching and learning programming</li>
        <li>Source Academy is used by all NUS computer science undergradutes for CS1101S</li>
        <li>Advocated for open-source: Source Academy now has >2,000 pull requests</li>
      </ul>` }
  ]
}

export const PUBLICATIONS: Section = {
  title: "Publications",
  entries: [
    { body: "<ol start=1><li id=\"crc\">" +
        "<u>Ning Yuan Lee</u>, Melissa Hum, Matthew Wong, " +
        "Pei-Yi Ong, Soo-Chin Lee, Ann S.G. Lee&ast; (2024). " +
        "\"Alleviating misclassified germline variants in underrepresented " +
        "populations: a strategy using popmax.\" " +
        "<span style='font-variant:small-caps'>In: </span> " +
        "<i>Genetics in Medicine</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1016/j.gim.2024.101124'>10.1016/j.gim.2024.101124</a></li></ol>" },
    { body: "<ol start=2><li id=\"met\">" +
        "<u>Ning Yuan Lee</u>, Melissa Hum, Guek Peng Tan, " +
        "Ai Choo Seah, Pei-Yi Ong, Patricia T Kin, Chia Wei Lim, " +
        "Jens Samol, Ngiap Chuan Tan, Hai-Yang Law, Min-Han Tan, " +
        "Soo-Chin Lee, Peter Ang, Ann S.G. Lee&ast; (2024). " +
        "\"Machine learning unveils an immune-related DNA methylation profile in germline DNA from breast cancer patients.\" " +
        "<span style='font-variant:small-caps'>In: </span> " +
        "<i>BMC Medicine</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1186/s13148-024-01674-2'>10.1186/s13148-024-01674-2</a></li></ol>"},
    { body: "<ol start=3><li id=\"stormet\">" +
        "<u>Ning-Yuan Lee</u>, Melissa Hum, Guek Peng Tan, Ai Choo Seah, " +
        "Patricia T Kin, Ngiap Chuan Tan, Hai-Yang Law, Ann S.G. Lee&ast; (2023)." +
        "\"Degradation of methylation signals in cryopreserved DNA.\" " +
        "<span style='font-variant:small-caps'>In: </span> " +
        "<i>Clinical Epigenetics</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1186/s13148-023-01565-y'>10.1186/s13148-023-01565-y</a></li></ol>" },
    { body: "<ol start=4><li id=\"lcc\">" +
        "<u>Ning-Yuan Lee</u>, Melissa Hum, Sabna Zihara, Lanying Wang, " +
        "Matthew K Myint, Darren Wan-Teck Lim, Chee-Keong Toh, Anders Skanderup, " +
        "Jens Samol, Min-Han Tan, Peter Ang, Soo-Chin Lee, Eng-Huat Tan, " +
        "Gillianne GY Lai, Daniel SW Tan, Yoon-Sim Yap, and Ann SG Lee&ast; (2023). " +
        "\"Landscape of germline pathogenic variants in patients with dual " +
        "primary breast and lung cancer\". " +
        "<span style='font-variant:small-caps'>In: </span>" +
        "<i>Human Genomics.</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1186/s40246-023-00510-7'>10.1186/s40246-023-00510-7</a></li></ol>" },
    { body: "<ol start=5><li id=\"bc\">" +
        "<u>Ning Yuan Lee</u>, Melissa Hum, Aseervatham Anusha Amali, " +
        "Wei Kiat Lim, Matthew Wong, Matthew Khine Myint, Ru Jin Tay, " +
        "Pei-Yi Ong, Jens Samol, Chia Wei Lim, Peter Ang, Min-Han Tan, " +
        "Soo-Chin Lee, and Ann S. G. Lee&ast; (2022). " +
        "\"Whole-Exome Sequences of <i>BRCA</i>-Negative Breast Cancer Patients " +
        "Case-Control Analyses Identify Variants Associated With Breast " +
        "Cancer Susceptibility\". " +
        "<span style='font-variant:small-caps'>In: </span>" +
        "<i>Human Genomics.</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1186/s40246-022-00435-7'>10.1186/s40246-022-00435-7</a></li></ol>" },
    { body: "<ol start=6><li id=\"npc\">" +
        "<u>Ning Yuan Lee</u>, Melissa Hum, Pei-Yi Ong, Matthew Khine Myint, " +
        "Enya HW Ong, Kar-Perng Low, Zheng Li, Boon-Cher Goh, Joshua K Tay, " +
        "Kwok-Seng Loh, Melvin LK Chua, Soo-Chin Lee, Chiea-Chuen Khor, and Ann SG Lee (2022). " +
        "\"Germline Variants Associated with Nasopharyngeal Carcinoma " +
        "Predisposition Identified through Whole-Exome Sequencing\". " +
        "<span style='font-variant:small-caps'>In: </span>" +
        "<i>Cancers.</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.3390/cancers14153680'>10.3390/cancers14153680</a></li></ol>" },
    { body: "<ol start=7><li id=\"the\">" +
        "<u>Ning Yuan Lee</u>&ast;, Zijun Wang, and Bernice Lim (2021). " +
        "\"The development of critical thinking: " +
        "What university students have to say\". " +
        "<span style='font-variant:small-caps'>In: </span>" +
        "<i>Teaching in Higher Education.</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1080/13562517.2021.1973412'>10.1080/13562517.2021.1973412</a></li></ol>" },
    { body: "<ol start=8><li id=\"ar\">" +
        "<u>Ning Yuan Lee</u> and Greg Tucker-Kellogg&ast; (2020). " +
        "\"An accessible, open-source mobile application for macromolecular " +
        "visualization using augmented reality\". " +
        "<span style='font-variant:small-caps'>In: </span> " +
        "<i>Biochemistry and Molecular Biology Education</i>. " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1002/bmb.21335'>10.1002/bmb.21335</a></li></ol>" }
  ]
}

export const AWARDS: Section = {
  title: "Awards",
  entries: [
    { title: "NCCS Research Publications Award (2022)",
      body: "For the article \"Germline Variants Associated with " +
        "Nasopharyngeal Carcinoma Predisposition Identified through " +
        "Whole-Exome Sequencing\" (<a href=\"#npc\">6</a>)" },
    // https://web.archive.org/web/20210817022209/https://nus.edu.sg/cdtl/teaching-and-learning-quality/teaching-awards/teaching-award-winners
    // https://web.archive.org/web/20221031160211/https://www.comp.nus.edu.sg/news/2021-atea-adea/
    { title: "Annual Digital Education Award (2021)",
      body: "Group award for the <i>Source Academy</i>, an online IDE for " +
        "teaching and learning programming, from the Centre for Development of Teaching &amp; Learning" },
    { title: "Sung Kah Kay Memorial Prize (2021)",
      body: "As the \"graduating student from the University Scholars Programme who best exemplifies scholarship and academic excellence.\"" },
    { title: "Honor List of Student Tutors (2020)",
      body: "\"For excellence in teaching during the Academic Year 2019–2020, from the NUS School of Computing\"" },
    { title: "Faculty of Science Dean's List (2020)",
      body: "For academic performance in the Spring semester" },
    { title: "USP Student Grant (2019)",
      body: "For expenses in the independent research project" },
    { title: "USP Honour Roll (2018), Senior Honour (2019), and President's Honour Roll (2021)",
      body: "\"For meritorious academic performance and contributions, " +
        "and achievements beyond the classroom.\"" },
    { title: "Lim Soo Peng Book Prize (2019)",
      body: "As the \"best student in Computer Science stream in the " +
        "Science II Examination for the degree of Bachelor of Science\"" }
  ]
}

export const SKILLS: Section = {
  title: "Technical Skills",
  entries: [
    { body:
      "<p>I am experienced in writing tidy, reproducible, and version-controlled workflows " +
      "using various workflow orchestrators (Snakemake, Nextflow, GNU Make) " +
      "across diverse platforms (Linux, Google Cloud, Amazon Web Services) for " +
      "array-based, next-generation sequencing, or traditional tabular data. " +
      "I am able to communicate and present computational results for a general " +
      "audience, in presentations or in scientific writing. </p>"
    }
  ]
}
